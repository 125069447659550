import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Cookies from "universal-cookie";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

Modal.setAppElement("#container");

class VenueRoomAddEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: "",
      capacity: 0,
      meeting_groups: false,
      meeting_groups_tables_small: 0,
      meeting_groups_tables_large: 0,
      priority: 0
    };
    this.addRoom = this.addRoom.bind(this);
    this.editRoom = this.editRoom.bind(this);
    this.disableGroups = this.disableGroups.bind(this);
    this.enableGroups = this.enableGroups.bind(this);
    this.updateCapacity = this.updateCapacity.bind(this);
    this.updateName = this.updateName.bind(this);
    this.updateTablesLarge = this.updateTablesLarge.bind(this);
    this.updateTablesSmall = this.updateTablesSmall.bind(this);
    this.updatePriority = this.updatePriority.bind(this);
  }

  static getDerivedStateFromProps(newProps, state) {
    if (!newProps.selectedRoomForEdit) {
      return null;
    }
    if (newProps.selectedRoomForEdit.id === state.id) {
      return null;
    }
    if (newProps.selectedRoomForEdit.id !== null) {
      const room = newProps.selectedRoomForEdit;
      return {
        id: room.id,
        name: room.name,
        capacity: room.capacity,
        meeting_groups: room.meeting_groups,
        meeting_groups_tables_small: room.meeting_groups_tables_small,
        meeting_groups_tables_large: room.meeting_groups_tables_large,
        priority: room.priority
      };
    }
    return {
      id: null,
      name: "",
      capacity: 0,
      meeting_groups: false,
      meeting_groups_tables_small: 0,
      meeting_groups_tables_large: 0,
      priority: 0
    };
  }

  clearForm() {
    this.setState({
      name: "",
      capacity: 0,
      meeting_groups: false,
      meeting_groups_tables_small: 0,
      meeting_groups_tables_large: 0,
      priority: 0
    });
  }

  updateName(event) {
    this.setState({ name: event.target.value });
  }

  updateCapacity(event) {
    this.setState({ capacity: event.target.value });
  }

  updateTablesSmall(event) {
    this.setState({ meeting_groups_tables_small: event.target.value });
  }

  updateTablesLarge(event) {
    this.setState({ meeting_groups_tables_large: event.target.value });
  }

  updatePriority(event) {
    this.setState({ priority: event.target.value });
  }

  enableGroups() {
    this.setState({ meeting_groups: true });
  }

  disableGroups() {
    this.setState({ meeting_groups: false });
  }

  modalLabel() {
    const { selectedRoomForEdit } = this.props;
    if (selectedRoomForEdit) {
      return "Edit Room";
    }
    return "Add Room";
  }

  renderSaveButton() {
    const { selectedRoomForEdit } = this.props;
    if (selectedRoomForEdit) {
      return (
        <button
          className="btn btn-primary btn-round"
          type="button"
          onClick={this.editRoom}
        >
          Update Room
        </button>
      );
    }
    return (
      <button
        className="btn btn-primary btn-round"
        type="button"
        onClick={this.addRoom}
      >
        Add Room
      </button>
    );
  }

  /* eslint-disable camelcase */
  addRoom() {
    const { venue, updateRooms, closeModal } = this.props;
    const {
      name,
      capacity,
      meeting_groups,
      meeting_groups_tables_small,
      meeting_groups_tables_large,
      priority
    } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {
      room: {
        name,
        capacity,
        meeting_groups,
        meeting_groups_tables_large,
        meeting_groups_tables_small,
        priority
      }
    };

    fetch(`/venues/${venue.id}/rooms`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          updateRooms(json.room);
          closeModal();
          this.clearForm();
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  /* eslint-disable camelcase */
  editRoom() {
    const { venue, updateRooms, closeModal, resetRoomForEdit } = this.props;
    const {
      id,
      name,
      capacity,
      meeting_groups,
      meeting_groups_tables_small,
      meeting_groups_tables_large,
      priority
    } = this.state;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    const data = {
      room: {
        name,
        capacity,
        meeting_groups,
        meeting_groups_tables_large,
        meeting_groups_tables_small,
        priority
      }
    };

    fetch(`/venues/${venue.id}/rooms/${id}`, {
      method: "put",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.error == null) {
          updateRooms(json.room);
          closeModal();
          if (resetRoomForEdit) {
            resetRoomForEdit();
          }
        } else {
          alert(json.error);
          console.log(json);
        }
      });
  }

  renderTableFields() {
    const {
      meeting_groups,
      meeting_groups_tables_small,
      meeting_groups_tables_large,
      priority
    } = this.state;
    const { config } = this.props;
    if (config.meeting_tables && meeting_groups) {
      return (
        <div className="room-table-fields">
          <div className="row">
            <div className="col-md-12">
              <label>
                How many <b>small</b> tables (4 people or fewer) will this room
                have?
              </label>
              <br />
              <input
                className="room-input"
                type="number"
                value={meeting_groups_tables_small}
                onChange={this.updateTablesSmall}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label>
                How many <b>large</b> tables (5 people or greater) will this
                room have?
              </label>
              <br />
              <input
                className="room-input"
                type="number"
                value={meeting_groups_tables_large}
                onChange={this.updateTablesLarge}
              />
            </div>
          </div>
          {/* <div className="row"> */}
          {/*   <div className="col-md-12"> */}
          {/*     <label> */}
          {/*       Set the priority value for this room (1 is top priority, 2 is */}
          {/*       second, etc.) */}
          {/*     </label> */}
          {/*     <br /> */}
          {/*     (0 priority rooms will not be used for 1:1 meetings, even if they */}
          {/*     have tables) */}
          {/*     <br /> */}
          {/*     <input */}
          {/*       className="room-input" */}
          {/*       type="number" */}
          {/*       value={priority} */}
          {/*       onChange={this.updatePriority} */}
          {/*     /> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
      );
    }
    return <React.Fragment />;
  }

  meetingTablesSettings() {
    const { config } = this.props;
    const { meeting_groups } = this.state;
    if (config.meeting_tables) {
      return (
        <div className="row">
          <div className="col-md-12">
            <label>Will this room host Executive 1:1 meetings?</label>
            <br />
            <input
              type="radio"
              name="meeting_groups"
              checked={meeting_groups}
              onChange={this.enableGroups}
            />{" "}
            Yes
            <br />
            <input
              type="radio"
              name="meeting_groups"
              checked={!meeting_groups}
              onChange={this.disableGroups}
            />{" "}
            No
            <br />
          </div>
        </div>
      );
    }
  }

  render() {
    const { modalOpen, closeModal } = this.props;
    const { name, capacity, meeting_groups } = this.state;
    return (
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel={this.modalLabel()}
      >
        <div className="venue-add-edit-modal">
          <h2>{this.modalLabel()}</h2>
          <div className="row">
            <div className="col-md-9">
              <label>Room Name</label>
              <br />
              <input
                type="text"
                className="room-input room-input-full"
                value={name}
                onChange={this.updateName}
              />
            </div>
            <div className="col-md-3">
              <label>Capacity</label>
              <br />
              <input
                type="number"
                className="room-input room-input-full"
                value={capacity}
                onChange={this.updateCapacity}
              />
            </div>
          </div>
          {this.meetingTablesSettings()}
          {this.renderTableFields()}
          <div className="row">
            <div className="col-md-12">
              <button
                className="btn btn-info btn-round"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              {this.renderSaveButton()}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
/* eslint-enable camelcase */

VenueRoomAddEdit.defaultProps = {
  resetRoomForEdit: () => {},
  selectedRoomForEdit: null
};

VenueRoomAddEdit.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  resetRoomForEdit: PropTypes.func,
  selectedRoomForEdit: PropTypes.object,
  updateRooms: PropTypes.func.isRequired,
  venue: PropTypes.object.isRequired
};

export default VenueRoomAddEdit;
